.btn{
	display: inline-flex !important;
	margin-top: 12px;
	padding: 13px 32px !important;
	font-family: $montserrat;
	font-size: rem(13) !important;
	font-weight: bold !important;
	text-transform: uppercase !important;
	text-decoration: none !important;
	text-align: center !important;
	line-height: rem(17) !important;
	letter-spacing: 0.1em !important;
	color: white !important;
	border-radius: 0 !important;
	border: 1px solid transparent;
	transition: all 0.2s ease !important;

	&--color-1{
		background-color: white;
		color: $font-color-1 !important;

		&:hover{
			background-color: $color-1;
			color: white !important;
		}
	}

	&--color-2{
		background-color: $color-2;

		&:hover{
			background-color: $color-1;
		}
	}

	&--color-3{
		background-color: transparent;
		border: 1px solid $color-1;
		color: $font-color-1 !important;

		&:hover{
			background-color: $color-1;
			color: white !important;
		}
	}
}
html{
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-font-line-height;
	color: $base-font-color;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
	width: 100%;
	height: 100%;

	&.nav-mobile-is-active{
		overflow: hidden;
	}
}

h1{
	font-family: $playfair;
	font-size: rem(36);
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2em;
	color: $font-color-1;
	margin: 1.5em 0 0;
}

h2{
	font-family: $playfair;
	font-size: rem(30);
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.2em;
	color: $font-color-1;
	margin: 1.5em 0 0;
}

h3{
	font-family: $base-font-family;
	font-size: rem(24);
	font-weight: normal;
	text-transform: uppercase;
	line-height: 1.2em;
	letter-spacing: 0.1em;
	color: $font-color-2;
	margin: 1.5em 0 0;
}

p{
	margin: 1.5em 0 0;
}

a{
	outline: none;
	text-decoration: none;
	color: inherit;
}

img{
	display: block;
	max-width: 100%;
	height: auto;
}

.page{
	width: 100%;
	height: 100%;

	&:not(.homepage){
		padding-top: 230px;

		.header{
			background-color: $color-3;
		}
	}
}

.container{
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 $gutter*1.5;
	width: 100%;
}

.wysi,
.cke_editable{
	width: 100%;
	
	> *{
		margin-top: 1.5em;
	}

	> *:first-child{
		margin-top: 0;
	}

	h1{
		& + h2,
		& + h3{
			margin-top: $gutter/3;
		}
	}

	h2{
		& + h1,
		& + h3{
			margin-top: $gutter/3;
		}
	}

	h3{
		& + h1,
		& + h2{
			margin-top: $gutter/3;
		}
	}

	a{
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

	.tbl-mobile{
		display: none;
	}

	.tbl-horizontal{
		tbody th{
			background-color: $color-1;
			border-top-color: white;
			color: white;
		}
	}

	table{
		width: 100%;
		text-align: left;
		border: 2px solid white;
		background-color: white;
	}

	thead th{
		background-color: $color-1;
		color: white;
	}

	tbody th{
		background-color: darken(white, 3);
	}

	tbody tr td,
	tbody tr th{
		border-top: 1px solid darken(white, 8);
	}

	tbody tr:first-child td,
	tbody tr:first-child th{
		border-top: none;
	}

	tr{
	}

	td,
	th{
		padding: 8px 12px;
		border: none;
	}

	td{
	}

	th{
		font-weight: bold;
	}
}

.edit{
	position: fixed;
	bottom: 20px;
	right: 20px;
	
	a{
		position: relative;
		display: block;
		width: 60px;
		height: 60px;
		background-color: #0F9D58;
		border-radius: 50%;
		overflow: hidden;
		text-indent: -999em;
		box-shadow: 0 2px 0 darken(#0F9D58, 10);

		&:hover{
			background-color: darken(#0F9D58, 2);
		}

		&:active{
			top: 2px;
			box-shadow: 0 0 0 transparent;
		}
	}
}

.edit__icon{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	fill: white;
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.page{
		&:not(.homepage){
			padding-top: 180px;
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){
	h1{
		font-size: rem(32);
		line-height: 1.2em;
	}

	h2{
		font-size: rem(26);
		line-height: 1.2em;
	}

	h3{
		font-size: rem(22);
		line-height: 1.2em;
	}

	.wysi{
		.tbl-mobile{
			display: table;
		}

		.tbl-horizontal.tbl-mobile,
		.tbl-vertical.tbl-mobile{
			th{
				border-top: none;

				& + *{
					border-top: none;
				}
			}

			.tbl-head{
				background-color: $color-1;
				color: white;
			}
		}

		.tbl-multidimensional.tbl-mobile{
			.tbl-head{
				background-color: $color-3;
				color: white;
			}

			.tbl-key,
			.tbl-value{
				width: 50%;
			}
		}

		table{
			display: none;
		}
	}
}

@media screen and (max-width: 639px){
	
}

@media screen and (max-width: 479px){

}
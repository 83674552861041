.slideshow{
	position: relative;
	z-index: 0;
	width: 100%;
	height: 100%;
	min-height: 768px;

	ul{
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0;
		margin: 0;
	}

	li{
		display: flex;
		width: 100%;
		height: 100%;
	}

	.flex-viewport{
		display: flex;
		width: 100%;
		height: 100%;
	}
}

.slideshow__slide{
	position: relative;
	z-index: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.slideshow__slide-content{
	display: flex;
	align-items: center;
	width: 100%;
	height: calc(100% - 230px);
	position: relative;
	z-index: 1;

	h2{
		font-size: rem(60);
	}

	h3{
		font-size: rem(20);
	}

	.wysi{
		max-width: 50%;
	}
}

.slideshow__slide-image{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

.fx-enabled 
.fx-in 
.slideshow__slide-image{
	transform: scale(1.025);
	transition: all 15s ease;
}

.slideshow__control{
	z-index: 99;
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	width: 40px;
	height: 76px;
	overflow: hidden;
	text-indent: -999em;
}

.slideshow__control-icon{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 40px;
	height: 76px;
	fill: white;
}

.slideshow__control--next{
	right: 30px;
}

.slideshow__control--prev{
	left: 30px;
}

.section--spotlight{
	padding: $gutter*4 0;

	.grid{
		margin: -$gutter;
		align-items: flex-start;
	}

	.col{
		flex: 0 0 auto;
		padding: $gutter;
	}

	.col--33{
		width: 33.33%;
	}

	.wysi{
		margin: 0 0 $gutter*2;
		text-align: center;
	}
}

.section--seo{
	padding: 120px 0;
	background-color: white;
}

.seo__gallery{
	margin-bottom: 80px;

	.grid{
		align-items: flex-start;
	}

	.hidden{
		display: none;
	}
}

.gallery__image{
	display: block;
	overflow: hidden;
	position: relative;

	img{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&--vertical{
		width: 100%;
		height: 0;
		padding-bottom: 150%;
	}

	&--horizontal{
		width: 100%;
		height: 0;
		padding-bottom: calc(75% - #{$gutter/2});

		img{
			max-width: none;
			max-height: 100%;
		}
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0;
		transition: all 0.2s ease;
	}

	&:after{
		content: attr(data-label);
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 2;
		top: $gutter;
		bottom: $gutter;
		left: $gutter;
		right: $gutter;
		border: 1px solid white;
		font-size: rem(13);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.25em;
		color: white;
		opacity: 0;
		transition: all 0.2s ease;
	}

	&:hover{
		&:before{
			opacity: 0.15;
		}

		&:after{
			opacity: 1;
		}
	}
}

.seo__content{
	.wysi{
		max-width: 768px;
		margin: 0 auto;
		text-align: center;
	}
}

.section--location{
	.grid{
		margin: 0;
		align-items: flex-start;
	}

	.col{
		padding: 0;
	}
}

.location__map{
	width: 100%;
	height: 600px;
	background-color: #ccc;
}

.location__info{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 600px;
	background-color: $color-3;

	.wysi{
		width: 100%;
		padding: 80px;
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.slideshow{
		height: 480px;
	}

	.slideshow__control{
		width: 20px;
		height: 38px;
	}

	.slideshow__control-icon{
		width: 20px;
		height: 38px;
	}

	.slideshow__slide-content{
		h2{
			font-size: rem(48);
		}

		h3{
			font-size: rem(16);
		}

		.wysi{
			max-width: 66%;
		}
	}

	.section--seo{
		padding: 60px 0;
	}

	.seo__gallery{
		margin-bottom: 60px;
	}

	.location__map{
		height: 480px;
	}

	.location__info{
		.wysi{
			padding: 60px 40px;
		}
	}
}

@media screen and (max-width: 959px){
	.section--location{
		.col{
			&:nth-child(1){
				order: 2;
			}

			&:nth-child(2){
				order: 1;
			}
		}
	}

	.location__info{
		height: auto;
	}
}

@media screen and (max-width: 767px){
	.slideshow__slide-content{
		.wysi{
			max-width: 100%;
		}
	}

	.seo__gallery{
		> .grid-container{
			> .grid{
				> .col{
					&:nth-child(1){
						order: 1;
					}

					&:nth-child(2){
						order: 3;
					}

					&:nth-child(3){
						order: 2;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 639px){
	.seo__gallery{
		> .grid-container{
			> .grid{
				> .col{
					&:nth-child(1){
						order: 1;
					}

					&:nth-child(2){
						order: 2;
					}

					&:nth-child(3){
						order: 3;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 479px){
	
}
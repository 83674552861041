/*
 * Fonts
 */
$base-font-family: 'Lato', sans-serif;
$base-font-size: 14px;
$base-font-line-height: 24px;
$base-font-color: #626262;

$montserrat: 'Montserrat', sans-serif;
$playfair: 'Playfair Display', serif;

/*
 * Colors
 */
$color-1: #3c3c3b;
$color-2: #626262;
$color-3: #e3e3e3;

$font-color-1: #3c3c3b;
$font-color-2: #626262;
$font-color-3: #e3e3e3;

$error: #df5555;
$success: #429e62;

/*
 * Gutters
 */
$gutter: 20px;
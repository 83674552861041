.nav--tabs{
	margin: $gutter*2 0;
	overflow: hidden;

	ul{
		padding: 0;
		margin: -1px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	li{
		display: inline-flex;
		padding: 1px;
	}

	a{
		display: flex;
		padding: 13px 32px;
		font-family: $montserrat;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-size: rem(13);
		font-weight: bold;
		line-height: rem(17);
		color: $font-color-1;
		transition: all 0.1s ease;
		border: 1px solid transparent;

		&:hover{
			border: 1px solid $color-1;
		}
	}

	.is-active{
		a{
			border: 1px solid $color-1;
		}
	}
}

.section--gallery-overview{
	padding: 120px 0;

	.grid{
		align-items: flex-start;
	}

	.col{
		flex: 0 0 auto;
	}

	.col--33{
		width: 33.33%;
	}

	.wysi{
		max-width: 768px;
		margin: 0 auto;
		text-align: center;
	}
}

.gallery__card{
	max-width: 640px;

	&:hover{
		img{
			transform: scale3d(1.1, 1.1, 1.1);
		}
	}
}

.gallery__card-image{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	img{
		transition: all 0.2s ease;
	}
}

@media screen and (max-width: 1199px){

}

@media screen and (max-width: 1023px){
	.section--gallery-overview{
		padding: 60px 0;
		
		.col--1023-50{
			width: 50%;
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){
	.section--gallery-overview{
		.col--639-100{
			width: 100%;
		}
	}
}

@media screen and (max-width: 479px){
	
}
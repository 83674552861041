.header{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;

	.nav--primary{
		margin-top: 27px;
	}

	.nav--social{
		flex: 0 0 auto;
		margin-top: 40px;

		a{
			&:hover{
				.icon{
					fill: white;
				}
			}
		}

		.icon{
			fill: $color-1;
			transition: all 0.2s ease;
		}
	}
}

.header__logo{
	display: block;
	width: 174px;
	height: 150px;
	margin: 0 120px 0 0;
	flex: 0 0 auto;
}

.header--primary{
	padding: 50px 0;

	.container{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.nav--mobile{
	display: none;
}

.nav--primary{
	flex: 0 0 auto;

	ul{
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	li{
		flex: 0 0 auto;
		display: inline-flex;
		padding: 12px 0;
		margin: 0 40px;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}

		&.is-active{
			border-bottom: 1px solid rgba(#3c3c3b, 0.2);
		}
	}

	a{
		display: block;
		font-family: $montserrat;
		font-size: rem(13);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		transition: all 0.1s ease;

		&:hover{
			color: $font-color-1;
		}
	}

	.is-active a{
		font-weight: 900;
		color: $font-color-1;
	}
}

.nav--languages{
	position: relative;

	ul{
		opacity: 0;
		padding: 0;
		margin: 0;
		flex-direction: column;

		.nav-languages-is-active &{
			opacity: 1;
			transition: opacity 0.2s ease;
		}
	}

	li{
		display: inline-flex;
	}

	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		font-size: rem(13);
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: white;
		transition: all 0.1s ease;

		&:not(.nav__toggle--languages){
			background-color: $color-3;

			&:hover{
				background-color: lighten($color-3, 5);
			}
		}
	}
}

.nav__toggle--languages{
	background-color: $color-2;

	&:hover{
		background-color: lighten($color-2, 5);
	}
}

.nav__toggle--languages + ul{
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
}

.header--secondary{
	background-color: $color-1;

	.container{
		display: flex;
		justify-content: flex-end;
	}
}

.nav--secondary{
	display: flex;

	ul{
		padding: 0;
		margin: 0;
		display: flex;
	}

	li{
		display: inline-flex;
	}

	a{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 $gutter;
		font-weight: bold;
		color: white;
		transition: all 0.1s ease;

		&:hover{
			background-color: $color-3;
		}
	}

	.is-active a{
		background-color: $color-3;
	}
}

.header--subpages{
	border-top: 1px solid darken(white, 8);
}

.nav--subpages{
	ul{
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
	}

	li{
		display: inline-flex;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}
	}

	a:not(.nav__toggle--subpages){
		display: flex;
		padding: 15px 20px;
		font-size: rem(12);
		font-weight: bold;
		text-transform: uppercase;
		line-height: rem(30);
		letter-spacing: 0.2em;
		transition: all 0.1s ease;

		&:hover{
			color: $font-color-1;
		}
	}

	.is-rootparent{
		display: none;
	}

	.is-active{
		a{
			font-weight: 900;
			color: $font-color-1;
		}
	}
}

.nav__toggle--subpages{
	display: none;
}

@media screen and (max-width: 1199px){
}

@media screen and (max-width: 1023px){
	.header{
		.nav--social{
			display: none;
		}
	}

	.header--primary{
		padding: 25px 0;
	}

	.header__logo{
		margin: 0;
	}

	.nav--primary,
	.nav--secondary{
		display: none;
	}

	.nav--mobile{
		margin-left: auto;
		margin-top: 32px;
		display: inline-flex;

		ul{
			position: fixed;
			z-index: 999;
			top: -100%;
			left: -100%;
			width: 100%;
			height: 100%;
			display: flex;
			padding: 0;
			margin: 0;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background-color: white;
			opacity: 0;
			transition: all 0s 0.2s, opacity 0.2s ease;

			.nav-mobile-is-active &{
				top: 0;
				left: 0;
				opacity: 1;
				transition: opacity 0.2s ease;
			}
		}

		li{
			display: inline-flex;

			&:first-child{
				padding-top: $gutter*2;
				margin-top: 0;
			}

			&:last-child{
				padding-bottom: $gutter*2;
			}
		}

		a{
			padding: 6px 12px;
			font-size: rem(17);
			line-height: rem(30);
			color: $font-color-2;
			transition: all 0.2s ease;

			&:hover{
				color: $font-color-1;
			}
		}

		.is-active{
			a{
				font-weight: bold;
				color: $font-color-1;
			}
		}
	}

	.nav__toggle--mobile{
		position: relative;
		z-index: 1000;
		width: 50px;
		height: 50px;
		display: flex;
		overflow: hidden;
		text-indent: -999em;

		.top,
		.mid,
		.btm{
			display: flex;
			width: 24px;
			height: 4px;
			position: absolute;
			left: 50%;
			margin-left: -12px;
			background-color: $color-2;
		}

		.top{
			top: calc(50% - 8px);
			margin-top: -2px;
			transition: top 0.1s 0.1s ease, transform 0.1s ease;

			.nav-mobile-is-active &{
				top: 50%;
				transform: rotate(45deg);
				transition: top 0.1s ease, transform 0.1s 0.1s ease;
			}
		}

		.mid{
			top: 50%;
			margin-top: -2px;

			.nav-mobile-is-active &{
				display: none;
			}
		}

		.btm{
			bottom: calc(50% - 8px);
			margin-bottom: -2px;
			transition: bottom 0.1s 0.1s ease, transform 0.1s ease;

			.nav-mobile-is-active &{
				bottom: 50%;
				transform: rotate(-45deg);
				transition: bottom 0.1s ease, transform 0.1s 0.1s ease;
			}
		}
	}

	.header--subpages{
		border-top: 0;
	}

	.nav--subpages{
		ul{
			display: none;
			flex-direction: column;
			margin-left: -($gutter * 1.5);
			margin-right: -($gutter * 1.5);

			.nav-subpages-is-active &{
				display: block;
			}
		}

		li{
			display: flex;
			border-top: 1px solid darken(white, 8);
		}

		a:not(.nav__toggle--subpages){
			padding: 15px 30px;
			width: 100%;

			&:before{
				content: '↪';
				font-size: rem(17);
				display: inline-flex;
				margin: 0 10px 0 0;
			}
		}

		.is-rootparent{
			display: flex;
			
			a:not(.nav__toggle--subpages){
				&:before{
					content: none;
				}
			}
		}
	}

	.nav__toggle--subpages{
		position: relative;
		display: flex;
		padding: 15px 30px;
		margin: 0 -30px;
		background-color: $color-3;
		font-size: rem(12);
		font-weight: bold;
		text-transform: uppercase;
		line-height: rem(30);
		letter-spacing: 0.2em;
		color: white;
		transition: all 0.1s ease;

		&:before{
			position: absolute;
			top: 50%;
			right: $gutter*1.5;
			margin-top: -2.5px;
			display: flex;
			content: '';
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid white;
			transition: all 0.2s ease;

			.nav-subpages-is-active &{
				transform: rotate(180deg);
			}
		}
	}
}

@media screen and (max-width: 959px){

}

@media screen and (max-width: 767px){

}

@media screen and (max-width: 639px){

}

@media screen and (max-width: 479px){
	
}